@import 'resources/css/mixins';
@import 'resources/css/variables';

::-webkit-scrollbar {
  @include px-to-rem(8, 'width');
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: $grey;
  @include border-radius(30);

  &:hover {
    background: $dark-grey;
  }

  &:active {
    background: $charcoal;
  }
}

::-webkit-scrollbar-button {
  display: none;
}
