$xs-screen-min: 414px;
$sm-screen-min: 576px;
$md-screen-min: 768px;
$lg-screen-min: 1025px;
$xl-screen-min: 1600px;
$xxl-screen-min: 1900px;

// Extra Extra large devices
@mixin xxl {
  @media (min-width: #{$xxl-screen-min}) {
    @content;
  }
}

// Extra large devices
@mixin xl {
  @media (min-width: #{$xl-screen-min}) {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (min-width: #{$lg-screen-min}) {
    @content;
  }
}

// Medium devices
@mixin md {
  @media (min-width: #{$md-screen-min}) {
    @content;
  }
}

// Small devices
@mixin sm {
  @media (min-width: #{$sm-screen-min}) {
    @content;
  }
}

// Extra small devices
@mixin xs {
  @media (min-width: #{$xs-screen-min}) {
    @content;
  }
}

// Custom Media Query
@mixin rwd($res) {
  @media (min-width: ($res+'px')) {
    @content;
  }
}
