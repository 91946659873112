@import 'resources/css/variables';
@import 'resources/css/mixins';

// Default Style
.button-link {
  text-decoration: none;
}

.btn {
  @include border-radius(3);

  font-family: 'Helvetica Neue LT W01 Bold Condensed', sans-serif;
  font-weight: bold;
  position: relative;
  text-transform: uppercase;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  outline: none;
  user-select: none;
  @include milli-em-to-em(30);
  @include px-to-rem(12, 'padding');

  transition: all 0.15s linear;
}

// Sizes

.btn-xl {
  @include px-to-rem(145, 'width');
  @include px-to-rem(16, 'font-size');
  @include px-to-rem(38, 'min-height');
  @include milli-em-to-em(30, 'letter-spacing');
}

.btn-l {
  @include px-to-rem(120, 'min-width');
  @include px-to-rem(14, 'font-size');
  @include px-to-rem(35, 'min-height');
  @include milli-em-to-em(30, 'letter-spacing');
}

.btn-s {
  @include px-to-rem(90, 'width');
  @include px-to-rem(9, 'font-size');
  @include px-to-rem(24, 'min-height');
  @include milli-em-to-em(40, 'letter-spacing');
}

.btn-sq {
  @include px-to-rem(35, 'width');
  @include px-to-rem(14, 'font-size');
  @include px-to-rem(35, 'min-height');
  @include px-to-rem(10, 'padding-right');
  @include px-to-rem(10, 'padding-left');

  font-family: 'Helvetica Neue LT W01 Medium', sans-serif;
  white-space: nowrap;

  .icon-component svg {
    @include px-to-rem(15, 'width');
    @include px-to-rem(15, 'height');
  }
}

.btn-right {
  float: right;
}

.btn-left {
  float: left;
}

// *********Color States***********
// disabled
.btn-disabled {
  cursor: not-allowed;
  color: $white;
  @include px-to-rem(1, 'border', solid $mid-grey);

  background-color: $mid-grey;

  .icon-component svg {
    fill: $white;
    cursor: inherit;

    g {
      fill: $white;

      rect {
        fill: $white;
      }

      path {
        fill: $white;
      }
    }
  }
}

// default
.btn-default {
  background-color: $i3d-red;
  color: $white;
  @include px-to-rem(1, 'border', solid $i3d-red);

  .icon-component svg {
    fill: $white;
    cursor: inherit;

    g {
      fill: $white;

      rect {
        fill: $white;
      }

      path {
        fill: $white;
      }
    }
  }

  &.btn-disabled,
  &.btn-disabled:hover {
    cursor: not-allowed;
    color: $white;
    border: none;
    background-color: $mid-grey;

    &:focus {
      background-color: $mid-grey;
    }
  }

  &:hover {
    cursor: pointer;
    background-color: $i3d-dark-red;
    @include px-to-rem(1, 'border', solid $i3d-dark-red);
  }

  &:active {
    cursor: pointer;
    background-color: $maroon;
    @include px-to-rem(1, 'border', solid $maroon);
  }
}
