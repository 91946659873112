@use 'sass:string';
@import 'resources/css/breakpoints';

$spaceamounts: (
  -24,
  -18,
  -12,
  -10,
  0,
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  20,
  24,
  27,
  25,
  30,
  31,
  32,
  35,
  38,
  40,
  42,
  45,
  48,
  50,
  55,
  60,
  65,
  70,
  75,
  80,
  84,
  85,
  100,
  110,
  120,
  125,
  130,
  144,
  150,
  160,
  200
);
$spaceamounts-percentages: (0, 25, 50, 75, 100);
$sides: (top, bottom, left, right);
$displays: (inline, inline-block, block, flex);
$positions: (relative, absolute, fixed, static);

/*
    Helper to automatically generate classes to add margin and padding (Fixed values)
    e.g. m-t-10 will add margin top of 10
*/

@each $space in $spaceamounts {
  @each $side in $sides {
    .m-#{string.slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space}px;
      margin-#{$side}: #{calc($space / 16)}rem;
    }

    .p-#{string.slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{$space}px;
      padding-#{$side}: #{calc($space / 16)}rem;
    }

    .#{string.slice($side, 0, 1)}-#{$space} {
      #{$side}: #{$space}px;
      #{$side}: #{calc($space / 16)}rem;
    }
  }

  .line-height-#{$space} {
    line-height: #{$space}px;
    line-height: #{calc($space / 16)}rem;
  }
}

// XXL screens
@media screen and (min-width: #{$xxl-screen-min}) {
  @each $space in $spaceamounts {
    @each $side in $sides {
      .m-#{string.slice($side, 0, 1)}-#{$space}-xxl {
        margin-#{$side}: #{$space}px;
        margin-#{$side}: #{calc($space / 16)}rem;
      }

      .p-#{string.slice($side, 0, 1)}-#{$space}-xxl {
        padding-#{$side}: #{$space}px;
        padding-#{$side}: #{calc($space / 16)}rem;
      }

      .#{string.slice($side, 0, 1)}-#{$space}-xxl {
        #{$side}: #{$space}px;
        #{$side}: #{calc($space / 16)}rem;
      }
    }
  }
}

// Extra large screens
@media screen and (min-width: #{$xl-screen-min}) {
  @each $space in $spaceamounts {
    @each $side in $sides {
      .m-#{string.slice($side, 0, 1)}-#{$space}-xl {
        margin-#{$side}: #{$space}px;
        margin-#{$side}: #{calc($space / 16)}rem;
      }

      .p-#{string.slice($side, 0, 1)}-#{$space}-xl {
        padding-#{$side}: #{$space}px;
        padding-#{$side}: #{calc($space / 16)}rem;
      }

      .#{string.slice($side, 0, 1)}-#{$space}-xl {
        #{$side}: #{$space}px;
        #{$side}: #{calc($space / 16)}rem;
      }
    }
  }
}

// Large screens
@media screen and (width <= 1600px) {
  @each $space in $spaceamounts {
    @each $side in $sides {
      .m-#{string.slice($side, 0, 1)}-#{$space}-l {
        margin-#{$side}: #{$space}px;
        margin-#{$side}: #{calc($space / 16)}rem;
      }

      .p-#{string.slice($side, 0, 1)}-#{$space}-l {
        padding-#{$side}: #{$space}px;
        padding-#{$side}: #{calc($space / 16)}rem;
      }

      .#{string.slice($side, 0, 1)}-#{$space}-l {
        #{$side}: #{$space}px;
        #{$side}: #{calc($space / 16)}rem;
      }
    }
  }
}

// Medium screens
@media screen and (width <= 1024px) {
  @each $space in $spaceamounts {
    @each $side in $sides {
      .m-#{string.slice($side, 0, 1)}-#{$space}-m {
        margin-#{$side}: #{$space}px;
        margin-#{$side}: #{calc($space / 16)}rem;
      }

      .p-#{string.slice($side, 0, 1)}-#{$space}-m {
        padding-#{$side}: #{$space}px;
        padding-#{$side}: #{calc($space / 16)}rem;
      }

      .#{string.slice($side, 0, 1)}-#{$space}-m {
        #{$side}: #{$space}px;
        #{$side}: #{calc($space / 16)}rem;
      }
    }
  }
}

// Small Screens
@media screen and (width <= 767px) {
  @each $space in $spaceamounts {
    @each $side in $sides {
      .m-#{string.slice($side, 0, 1)}-#{$space}-sm {
        margin-#{$side}: #{$space}px;
        margin-#{$side}: #{calc($space / 16)}rem;
      }

      .p-#{string.slice($side, 0, 1)}-#{$space}-sm {
        padding-#{$side}: #{$space}px;
        padding-#{$side}: #{calc($space / 16)}rem;
      }

      .#{string.slice($side, 0, 1)}-#{$space}-sm {
        #{$side}: #{$space}px;
        #{$side}: #{calc($space / 16)}rem;
      }
    }
  }
}

/*
    Helper to automatically generate classes to add margin and padding in all sides (top, right, bottom, left, Fixed values)
    e.g. m-a-10 will add margin of 10
*/

@each $space in $spaceamounts {
  .m-a-#{$space} {
    margin: #{$space}px;
    margin: #{calc($space / 16)}rem;
  }

  .p-a-#{$space} {
    padding: #{$space}px;
    padding: #{calc($space / 16)}rem;
  }
}

/*
    Helper to automatically generate classes for width (Percentage values)
    e.g. w-50-perc will make the width of the element of 50%
*/

@each $space in $spaceamounts {
  .w-#{$space}-perc {
    width: #{$space}#{'%'};
  }

  .h-#{$space}-perc {
    height: #{$space}#{'%'};
  }
}

@each $display in $displays {
  .#{$display} {
    display: $display;
  }
}

@each $position in $positions {
  .pos-#{$position} {
    position: $position;
  }
}
