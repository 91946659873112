@import 'resources/css/variables';
@import 'resources/css/mixins';
@import 'resources/css/breakpoints';

.error-page {
  height: 100vh;
  display: flex;
  align-content: center;

  .error-container {
    display: flex;
    background: $white;
    @include border-radius(3, 3, 3, 3);

    .left,
    .right {
      @include px-to-rem(20, 'padding');
    }

    .left {
      @include px-to-rem(500, 'max-width');
    }

    .right {
      svg {
        @include px-to-rem(300, 'width');
        @include px-to-rem(300, 'height');
      }
    }
  }
}
