@import 'resources/css/variables';
@import 'resources/css/mixins';

.loading-center {
  position: absolute;

  /* NOTE: This value should be fixed to when the sidemenu is opened of closed */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
