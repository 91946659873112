// Converts px to rem
// The extra are extra properties, e.g. border 1px solid black -> the extra is 'solid black'
@mixin px-to-rem($px, $property: 'font-size', $extra: '', $default-size: 16) {
  #{$property}: $px + px + ' ' + $extra; // Fallback for IE8
  #{$property}: calc($px / $default-size) + rem + ' ' + $extra;
}

// Convert milli-ems to ems
@mixin milli-em-to-em($mem, $property: 'letter-spacing', $extra: '') {
  #{$property}: calc($mem / 1000) + em + ' ' + $extra;
}

// Converts px to rem in 4 dimensions
@mixin px-to-rem-4d($px-top, $px-right, $px-bottom, $px-left, $property: 'margin', $extra: '', $default-size: 16) {
  #{$property}: $px-top + px $px-right + px $px-bottom + px $px-left + px + ' ' + $extra; // Fallback for IE8
  #{$property}: calc($px-top / $default-size) + rem calc($px-right / $default-size) + rem calc($px-bottom / $default-size) + rem
    calc($px-left / $default-size) + rem + ' ' + $extra;
}

// Border Radius with all the vendor prefixes
@mixin border-radius($px-top, $px-right: $px-top, $px-bottom: $px-top, $px-left: $px-top) {
  border-radius: $px-top + px $px-right + px $px-bottom + px $px-left + px;
}

// User select for all vendor prefixes
@mixin user-select($value: 'none') {
  -webkit-touch-callout: none;
  user-select: none;
}

// Dropdown icon
@mixin dropdown-icon($color: variables.$white) {
  content: '';
  @include px-to-rem(5, 'border-left', solid transparent);
  @include px-to-rem(5, 'border-right', solid transparent);
  @include px-to-rem(7, 'border-top', solid $color);
}

// Draws a circle
@mixin draw-circle($color: variables.$green) {
  display: block;
  @include px-to-rem(20, 'border-radius');
  @include px-to-rem(20, 'width');
  @include px-to-rem(20, 'height');

  background: $color;
}
