@import 'resources/css/variables';
@import 'resources/css/mixins';
@import 'resources/css/breakpoints';

.col-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: auto;
  @include px-to-rem(24, 'column-gap');
  @include px-to-rem-4d(0, 24, 0, 24, 'margin');
}

.grid.no-gap {
  column-gap: 0;
}

.grid.no-margin {
  margin: 0;
}

.grid.no-padding {
  padding: 0;
}

.col {
  word-break: break-word;
  grid-auto-rows: min-content;
}

.col.offset-1,
.col.col-1 {
  grid-column: span 1;
}

.col.offset-2,
.col.col-2 {
  grid-column: span 2;
}

.col.offset-3,
.col.col-3 {
  grid-column: span 3;
}

.col.offset-4,
.col.col-4 {
  grid-column: span 4;
}

.col.offset-5,
.col.col-5 {
  grid-column: span 5;
}

.col.offset-6,
.col.col-6 {
  grid-column: span 6;
}

.col.offset-7,
.col.col-7 {
  grid-column: span 7;
}

.col.offset-8,
.col.col-8 {
  grid-column: span 8;
}

.col.offset-9,
.col.col-9 {
  grid-column: span 9;
}

.col.offset-10,
.col.col-10 {
  grid-column: span 10;
}

.col.offset-11,
.col.col-11 {
  grid-column: span 11;
}

.col.offset-12,
.col.col-12 {
  grid-column: span 12;
}

.grid.align-right {
  direction: rtl;
}

/* Large screens */

@media screen and (width <= 1600px) {
  .col.col-l-1 {
    grid-column: span 1;
  }

  .col.col-l-2 {
    grid-column: span 2;
  }

  .col.col-l-3 {
    grid-column: span 3;
  }

  .col.col-l-4 {
    grid-column: span 4;
  }

  .col.col-l-5 {
    grid-column: span 5;
  }

  .col.col-l-6 {
    grid-column: span 6;
  }

  .col.col-l-7 {
    grid-column: span 7;
  }

  .col.col-l-8 {
    grid-column: span 8;
  }

  .col.col-l-9 {
    grid-column: span 9;
  }

  .col.col-l-10 {
    grid-column: span 10;
  }

  .col.col-l-11 {
    grid-column: span 11;
  }

  .col.col-l-12 {
    grid-column: span 12;
  }
}

/* Medium screens */

@media screen and (width <= 1024px) {
  .col.col-m-1 {
    grid-column: span 1;
  }

  .col.col-m-2 {
    grid-column: span 2;
  }

  .col.col-m-3 {
    grid-column: span 3;
  }

  .col.col-m-4 {
    grid-column: span 4;
  }

  .col.col-m-5 {
    grid-column: span 5;
  }

  .col.col-m-6 {
    grid-column: span 6;
  }

  .col.col-m-7 {
    grid-column: span 7;
  }

  .col.col-m-8 {
    grid-column: span 8;
  }

  .col.col-m-9 {
    grid-column: span 9;
  }

  .col.col-m-10 {
    grid-column: span 10;
  }

  .col.col-m-11 {
    grid-column: span 11;
  }

  .col.col-m-12 {
    grid-column: span 12;
  }
}

@media screen and (width <= 767px) {
  .grid {
    display: block;
  }

  .col.offset-1,
  .col.offset-2,
  .col.offset-3,
  .col.offset-4,
  .col.offset-5,
  .col.offset-6,
  .col.offset-7,
  .col.offset-8,
  .col.offset-9,
  .col.offset-10,
  .col.offset-11,
  .col.offset-12 {
    grid-column: span 0;
  }

  .col.col-1,
  .col.col-l-1,
  .col.col-m-1,
  .col.col-2,
  .col.col-l-2,
  .col.col-m-2,
  .col.col-3,
  .col.col-l-3,
  .col.col-m-3,
  .col.col-4,
  .col.col-l-4,
  .col.col-m-4,
  .col.col-5,
  .col.col-l-5,
  .col.col-m-5,
  .col.col-6,
  .col.col-l-6,
  .col.col-m-6,
  .col.col-7,
  .col.col-l-7,
  .col.col-m-7,
  .col.col-8,
  .col.col-l-8,
  .col.col-m-8,
  .col.col-9,
  .col.col-l-9,
  .col.col-m-9,
  .col.col-10,
  .col.col-l-10,
  .col.col-m-10,
  .col.col-11,
  .col.col-l-11,
  .col.col-m-11,
  .col.col-12,
  .col.col-l-12,
  .col.col-m-12 {
    grid-column: span 12;
  }
}
